import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import JoditEditor from 'jodit-react';
import { Row, Col, Container, Form, Button } from 'react-bootstrap';
import firebase from 'firebase/app';
import 'firebase/storage';
import Datetime from 'react-datetime';
import Select from 'react-select';
import Loader from '../Components/Loader';
import { updateDoc, getDocInfo, getSpeakers, getEventImages } from '../Functions/FirestoreFunctions';
import { validateEventForm } from '../Functions/AdminFunctions';

const config = {
  readonly: false,
};

const moment = require('moment');

const EditEvent = ({ history }) => {
  const { eventId } = useParams();
  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState({});
  const [agenda, setAgenda] = useState(undefined);
  const [description, setDescription] = useState(undefined);
  const [allSpeakers, setAllSpeakers] = useState({ value: '', label: '' });
  const [images, setImages] = useState(undefined);
  const [imagesForDeletion, setImagesForDeletion] = useState([]);

  const [outsideFoyerImage, setOutsideFoyerImage] = useState(undefined);
  const [foyerImage, setFoyerImage] = useState(undefined);
  const [iFrameBackground, setIFrameBackground] = useState(undefined);

  useEffect(() => {
    getSpeakers().then((response) => {
      setAllSpeakers(response.map((x) => ({ value: x.id, label: x.data().title })));
    });
  }, []);

  useEffect(() => {
    if (eventId) {
      getDocInfo('events', eventId).then((result) => {
        setFormData(result);
        getEventImages(eventId, result).then((res) => {
          setImages(res);
        });
        setAgenda(result.agenda);
        setDescription(result.description);
        setLoading(false);
      });
    }
  }, [eventId]);

  const [errorBag, setErrorBag] = useState([]);

  function updateFormData(update) {
    const newFormData = { ...formData };
    newFormData[update.field] = update.value;
    setFormData(newFormData);
  }

  function addImageForDeletion(imageName) {
    const newImagesForDeletion = [...imagesForDeletion];
    newImagesForDeletion.push(imageName);
    setImagesForDeletion(newImagesForDeletion);
  }

  function handleRemoveImage(e) {
    addImageForDeletion(formData[e.target.id]);
    const newImages = { ...images };
    newImages[e.target.id] = undefined;
    setImages(newImages);
    updateFormData({ field: e.target.id, value: undefined });
  }

  useEffect(() => {
    updateFormData({ field: 'agenda', value: agenda });
  }, [agenda]);

  useEffect(() => {
    updateFormData({ field: 'description', value: description });
  }, [description]);

  useEffect(() => {
    if (foyerImage) {
      updateFormData({ field: 'foyerImage', value: foyerImage.name });
    }
  }, [foyerImage]);

  useEffect(() => {
    if (outsideFoyerImage) {
      updateFormData({ field: 'outsideFoyerImage', value: outsideFoyerImage.name });
    }
  }, [outsideFoyerImage]);

  useEffect(() => {
    if (iFrameBackground) {
      updateFormData({ field: 'iFrameBackground', value: iFrameBackground.name });
    }
  }, [iFrameBackground]);

  function validateImages() {
    return [];
  }

  function updateEvent() {
    const errors = validateEventForm(formData);
    const imageErrors = validateImages();
    if (errors.length === 0 && imageErrors.length === 0) {
      try {
        updateDoc('events', eventId, formData).then(() => {
          const storageRef = firebase.storage().ref();

          if (foyerImage) {
            const ref = storageRef.child(`events/${eventId}/${foyerImage.name}`);
            ref.put(foyerImage);
          }

          if (outsideFoyerImage) {
            const ref = storageRef.child(`events/${eventId}/${outsideFoyerImage.name}`);
            ref.put(outsideFoyerImage);
          }

          if (iFrameBackground) {
            const ref = storageRef.child(`events/${eventId}/${iFrameBackground.name}`);
            ref.put(iFrameBackground);
          }

          // todo this does not delete. Permissions error
          // imagesForDeletion.forEach((imageToDelete) => {
          //   const ref = storageRef.child(`events/${eventId}/${imageToDelete})`);
          //   ref.delete();
          // });

          history.push('/admin');
        });
      } catch (e) {
        console.log(e);
      }
    } else {
      setErrorBag([...errors, ...imageErrors]);
    }
  }

  return loading ? (<Loader />) : (
    <div className="container page-container">
      <Container>
        <ul>
          {errorBag.map((error) => (
            <li className="text-danger">{error}</li>
          ))}
        </ul>

        <div className="form-group">
          <Row>
            <Col md={6}>
              <label htmlFor="titleInput">Event title</label>
              <input
                type="text"
                id="titleInput"
                className="form-control mb-2"
                placeholder="Event title"
                value={formData.title}
                onChange={(e) => {
                  updateFormData({ field: 'title', value: e.target.value });
                }}
              />
            </Col>
            <Col md={6}>
              <label htmlFor="locationInput">Location</label>
              <input
                type="text"
                id="locationInput"
                className="form-control mb-2"
                placeholder="Location"
                value={formData.location}
                onChange={(e) => {
                  updateFormData({ field: 'location', value: e.target.value });
                }}
              />
            </Col>
            <Col md={6}>
              <label htmlFor="facultyInput">Faculty</label>
              <input
                type="text"
                id="facultyInput"
                className="form-control mb-2"
                placeholder="Faculty"
                value={formData.faculty}
                onChange={(e) => {
                  updateFormData({ field: 'faculty', value: e.target.value });
                }}
              />
            </Col>
            <Col md={6}>
              <label htmlFor="iFrameURL">iFrameURL</label>
              <input
                type="text"
                id="iFrameURL"
                className="form-control mb-2"
                placeholder="iFrameURL"
                value={formData.iFrameURL}
                onChange={(e) => {
                  updateFormData({ field: 'iFrameURL', value: e.target.value });
                }}
              />
            </Col>
            <Col md={6}>
              <label htmlFor="feedbackForm">Feedback form URL</label>
              <input
                type="text"
                id="feedbackForm"
                className="form-control mb-2"
                placeholder="Feedback form"
                value={formData.feedbackForm}
                onChange={(e) => {
                  updateFormData({ field: 'feedbackForm', value: e.target.value });
                }}
              />
            </Col>
            <Col md={12}>
              <label htmlFor="description">Description</label>
              <JoditEditor
                id="description"
                value={description}
                config={config}
                onBlur={(newContent) => setDescription(newContent)}
              />
            </Col>
            <Col md={12}>
              <label htmlFor="agenda">Agenda</label>
              <JoditEditor
                id="agenda"
                value={agenda}
                config={config}
                onBlur={(newContent) => setAgenda(newContent)}
              />
            </Col>
            <Col md={6}>
              <label>Start date and time  (GMT)</label>
              <Datetime
                onChange={(e) => {
                  updateFormData({ field: 'start', value: e.toISOString() });
                }}
                dateFormat="DD/MM/YYYY"
                value={moment(formData.start)}
              />
            </Col>
            <Col md={6}>
              <label>End date and time  (GMT)</label>
              <Datetime
                onChange={(e) => {
                  updateFormData({ field: 'end', value: e.toISOString() });
                }}
                dateFormat="DD/MM/YYYY"
                value={moment(formData.end)}
              />
            </Col>
            <Col md={12}>
              <Form.Label>Outside Foyer Image - 185px wide by 270px high</Form.Label>
              {(images && images.outsideFoyerImage) ? (
                <>
                  <img src={images.outsideFoyerImage} alt="profile-pic" height="135" width="92" />
                  <Button id="outsideFoyerImage" variant="danger" onClick={(e) => handleRemoveImage(e)}>
                    x
                  </Button>
                </>
              ) : (
                <Form.File
                  onChange={(e) => {
                    setOutsideFoyerImage(e.target.files[0]);
                  }}
                  id="speaker-profile-upload"
                />
              )}
            </Col>
            <Col md={12}>
              <Form.Label>Foyer Image - 165px wide by 423px high</Form.Label>
              {(images && images.foyerImage) ? (
                <>
                  <img src={images.foyerImage} alt="profile-pic" height="211" width="82" />
                  <Button id="foyerImage" variant="danger" onClick={(e) => handleRemoveImage(e)}>
                    x
                  </Button>
                </>
              ) : (
                <Form.File
                  onChange={(e) => {
                    setFoyerImage(e.target.files[0]);
                  }}
                  id="speaker-profile-upload"
                />
              )}
            </Col>
            <Col md={12}>
              <Form.Label>IFrame Background - 1134px wide by 671px high  x </Form.Label>
              {(images && images.iFrameBackground) ? (
                <>
                  <img src={images.iFrameBackground} alt="profile-pic" height="134" width="226" />
                  <Button id="iFrameBackground" variant="danger" onClick={(e) => handleRemoveImage(e)}>
                    x
                  </Button>
                </>
              ) : (
                <Form.File
                  onChange={(e) => {
                    setIFrameBackground(e.target.files[0]);
                  }}
                  id="speaker-profile-upload"
                />
              )}
            </Col>
            <Col htmlFor="iFrameBackground" md={12} className="my-4">
              <Select options={allSpeakers} value={formData.speakers} onChange={(e) => { updateFormData({ field: 'speakers', value: e }); }} isMulti />
            </Col>
          </Row>
        </div>
        <div className="form-buttons-container mt-3">
          <button id="btnSignUp" className="btn btn-warning text-white mr-3 float-right" onClick={updateEvent} type="button">
            Update
          </button>
        </div>
      </Container>
    </div>
  );
};

export default EditEvent;
