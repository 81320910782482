import React, { useState, useCallback } from 'react';
import { Row, Button } from 'react-bootstrap';
import {
  registerUser,
  getEmptyRegistrationForm,
} from '../../Functions/AuthFunctions';

import TextField from './FormFields/TextField';

const RegistrationForm = () => {
  const [formData, setFormData] = useState(getEmptyRegistrationForm);
  const [errorBag, setErrorBag] = useState([]);

  function updateFormData(update) {
    const newFormData = { ...formData };
    newFormData[update.field] = update.value;
    setFormData(newFormData);
  }

  const handleSignup = useCallback(async (event) => {
    event.preventDefault();

    registerUser(formData).then((res) => {
      if (res && res.message) {
        setErrorBag([res.message]);
      }
    });
  });

  function getFormField(type, size, field, label, required = false) {
    return (
      <TextField
        type={type}
        size={size}
        field={field}
        label={label}
        formData={formData}
        updateFormData={updateFormData}
        required={required}
      />
    );
  }

  return (
    <>
      <ul>
        {errorBag.map((error) => (
          <li className="text-danger">{error}</li>
        ))}
      </ul>
      <div className="form-group">
        <Row>

          {getFormField('email', 6, 'email', 'Email', true)}
          {getFormField('password', 6, 'password', 'Password', true)}

        </Row>
      </div>
      <div className="text-center">
        <Button
          id="btnSignUp"
          variant="primary"
          className="mr-3 text-white mb-3"
          onClick={handleSignup}
          type="button"
          size="lg"
        >
          Register
        </Button>
      </div>
    </>
  );
};

export default RegistrationForm;
