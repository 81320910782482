import React, { useEffect, useState } from 'react';
// import React, { useEffect, useState, useContext } from 'react';
// import firebase from 'firebase/app';
import 'firebase/database';
import { Jumbotron } from 'react-bootstrap';
// import { AuthContext } from '../Auth/Auth';
import Foreground from '../Components/Animation/Foreground';
import Foyer from '../Components/Animation/Foyer';
import LoadingBar from '../Components/Animation/LoadingBar';
import DetailsPane from '../Components/Animation/DetailsPane';
import { getDocInfo, getEventImages } from '../Functions/FirestoreFunctions';
import Auditorium from '../Components/Animation/Auditorium';
import Webinar from '../Components/Animation/Webinar';
import EventUserTracker from '../Auth/EventUserTracker';
import PortraitOverlay from '../Components/Animation/PortraitOverlay';
import CustomPollContainer from '../Components/CustomPollContainer';
import DetailsModal from '../Components/Modals/DetailsModal';

const LiveEventContainer = () => {
  const [loading, setLoading] = useState(0);
  const [stage, setStage] = useState(1);
  const [details, setDetails] = useState({});
  const [eventImages, setEventImages] = useState(undefined);
  // const { currentUser } = useContext(AuthContext);
  const eventId = 'eG9QFc7l6BLQefdxSmjK';
  document.body.classList.add('live-event');
  /* Change to TRUE if you want to skip animation for dev */
  const goToEnd = false;

  /* eslint-disable */
  /* Only start animation if device is landscape, otherwise css vw width are calculated incorrectly */
  const isLandscape = () => window.matchMedia('(orientation:landscape)').matches;
  const [orientation, setOrientation] = useState(isLandscape() ? 'landscape' : 'portrait');
  const onWindowResize = () => {
    clearTimeout(window.resizeLag);
    window.resizeLag = setTimeout(() => {
      delete window.resizeLag;
      setOrientation(isLandscape() ? 'landscape' : 'portrait');
    }, 200);
  };

  useEffect(() => {
    onWindowResize(),
      window.addEventListener('resize', onWindowResize),
      () => window.removeEventListener('resize', onWindowResize);
  }, []);

  /* eslint-enable */

  useEffect(() => {
    if (orientation === 'landscape' && loading !== 100) {
      setLoading(33);
      getDocInfo('events', eventId).then((result) => {
        setLoading(66);
        setDetails(result);
        getEventImages(eventId, result).then((images) => {
          setEventImages(images);
          setLoading(100);
        });
      });
    }
  }, [eventId, orientation]);

  return (
    <>
      <CustomPollContainer />
      <EventUserTracker />
      <Jumbotron className="animation__container" fluid>
        <PortraitOverlay />
        <span
          className={`${
            goToEnd
              ? 'gotoend'
              : `${loading === 100 ? 'stage-1' : ''} ${stage === 2 ? 'stage-2' : ''}`
          }`}
        >
          <LoadingBar progress={loading} />
          <Foreground billboardImage={eventImages ? eventImages.outsideFoyerImage : undefined} />
          <Foyer
            foyerImage={eventImages ? eventImages.foyerImage : undefined}
            setStage={setStage}
          />
          <DetailsPane details={details} />

          <Auditorium />
          <Webinar event={details} image={eventImages ? eventImages.iFrameBackground : undefined} />
        </span>
      </Jumbotron>
      <DetailsModal />
      <div className="live-event-footer left">
        All LEO Pharma trademarks mentioned belong to LEO Pharma A/S
        <br />
        © September 2024, LEO Pharma A/S
        <br />
        Date of preparation: September 2024
        <br />
        Global: MAT-76346 <br />
        {/* UK: MAT-69407 */}
      </div>
      {/* <div className="live-event-footer right">
        Reporting of Suspected Adverse Reactions for UK
        <br />
        Adverse events should be reported.
        <br />
        For the United Kingdom, Reporting forms and information can be found at:{' '}
        <a href="https://yellowcard.mhra.gov.uk/" target="_blank" rel="noreferrer">
          yellowcard.mhra.gov.uk
        </a>
        <br />
        Adverse events should also be reported to Drug Safety at LEO Pharma by calling{' '}
        <a href="tel:+441844347333" target="_blank" rel="noreferrer">
          +44 (0)1844 347333
        </a>{' '}
        or e-mail{' '}
        <a
          href="mailto:medical-info.uk@leo-pharma.com"
          title="mailto:medical-info.uk@leo-pharma.com"
          target="_blank"
          rel="noreferrer"
        >
          medical-info.uk@leo-pharma.com
        </a>{' '}
        <br />
        <a
          href="https://www.leo-pharma.co.uk/contact/pharmacovigilance"
          target="_blank"
          rel="noreferrer"
        >
          Click here to report an adverse event
        </a>
      </div> */}
    </>
  );
};

export default LiveEventContainer;
