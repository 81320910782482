import React, { useState } from 'react';
import { Col, Row, Form } from 'react-bootstrap';
import { useHistory } from 'react-router';
import { triggerForgotPassword } from '../Functions/AuthFunctions';

const LoginPage = () => {
  document.title = 'Derma Webinar - Forgotten Password';
  const history = useHistory();
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');

  function handleForgotPassword() {
    if (email) {
      triggerForgotPassword(email).then((result) => {
        if (result) {
          // Show firebase error if any
          setError(result);
        } else {
          history.push('/login');
        }
      });
    } else {
      setError('Email field is required');
    }
  }

  return (
    <div className="container page-container">
      <Row className="home-row">
        <Col lg={12} className="px-0">
          <h2 className="form-header mb-1">
            Please complete the form below to request the password link:
          </h2>
          <Col md={12} className="mb-3 px-0">
            {error && <li className="text-danger mb-3">{error}</li>}
            <Form.Group>
              <label htmlFor="txtEmail">Email</label>
              <input
                type="email"
                id="txtEmail"
                className="form-control mb-2"
                placeholder="Email"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
            </Form.Group>
          </Col>
          <div className="form-buttons-container">
            <button
              id="btnSignUp"
              className="btn btn-sm btn-primary text-white float-right"
              onClick={handleForgotPassword}
              type="button"
            >
              Send Link
            </button>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default LoginPage;
