/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { Tabs, Tab, Container } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { getDocInfo } from '../../Functions/FirestoreFunctions';
import TitleBanner from '../../Components/TitleBanner';
import ActiveUsers from './ActiveUsers';
import EventQuestions from './EventQuestions';
import Polls from './Polls';
import CustomPolls from './CustomPolls/CustomPolls';

const EventDetails = () => {
  const { eventId } = useParams();
  const [eventInfo, setEventInfo] = useState({});

  useEffect(() => {
    getDocInfo('events', eventId).then((result) => {
      setEventInfo(result);
    });
  }, []);

  return (
    <div className="container page-container">
      <TitleBanner title="Event Dashboard" />
      <h1 className="text-center mt-5">{eventInfo ? eventInfo.title : ''}</h1>
      <Container className="mb-5">
        <Tabs defaultActiveKey="custom" id="event-details" className="mt-5">
          {/* <Tab eventKey="questions" title="Questions">
            <EventQuestions eventId={eventId} />
          </Tab>
          <Tab eventKey="activeUsers" title="Active Users">
            <ActiveUsers eventId={eventId} />
          </Tab> */}
          <Tab eventKey="custom" title="Custom Polls">
            <CustomPolls eventId={eventId} />
          </Tab>
          <Tab eventKey="polls" title="IFrame Poll">
            <Polls eventId={eventId} />
          </Tab>
        </Tabs>
      </Container>
    </div>
  );
};

export default EventDetails;
