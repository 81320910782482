import React from 'react';
import { Col, Form } from 'react-bootstrap';

function Text({ type, size, field, label, formData, updateFormData, required }) {
  return (
    <Col md={size}>
      <Form.Group controlId={`register.${field}`}>
        <Form.Label className="text-dark-grey font-weight-bold">
          {label}
          {required && <sup className="text-danger">*</sup>}
        </Form.Label>
        <Form.Control
          type={type}
          className="mb-2"
          placeholder={label}
          value={formData[field]}
          onChange={(e) => {
            updateFormData({ field, value: e.target.value });
          }}
        />
      </Form.Group>
    </Col>
  );
}

export default Text;
