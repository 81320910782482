import React, { useState, useEffect, useCallback } from 'react';
import firebase from 'firebase/app';
import 'firebase/database';
import CustomPoll from './CustomPoll';
import Poll from './Poll';
import CustomPollResults from './CustomPollResults';

function CustomPollContainer() {
  const [customPoll, setCustomPoll] = useState(false);
  const [image, setImageUrl] = useState('');
  const [visitorId, setVisitorId] = useState(localStorage.getItem('visitorID_nov_24'));
  const database = firebase.database();

  const eventId = 'eG9QFc7l6BLQefdxSmjK';
  const [show, setShow] = useState(false);
  const [form, setForm] = useState(false);
  const [eventInfo, setEventInfo] = useState(false);

  useEffect(() => {
    firebase
      .database()
      .ref('customPolls')
      .on('value', (snapshot) => {
        console.log('AA');
        if (snapshot.val()) {
          const pollArray = Object.keys(snapshot.val()).map((id) => ({
            id,
            ...snapshot.val()[id],
          }));

          const pollData = pollArray
            .filter((poll) => poll.deleted === undefined)
            .filter((poll) => poll.status === 'live')
            .filter(
              (poll) => !localStorage.getItem(poll.id) && Date.now() - poll.timestamp < 200000,
            );

          const pollResultsData = pollArray
            .filter((result) => result.deleted === undefined)
            .filter((result) => result.showResults === true)
            .filter(
              (result) =>
                !localStorage.getItem(`${result.id}_results`) &&
                Date.now() - result.resultsTimestamp < 200000,
            );

          const allPolls = [...pollResultsData, ...pollData].sort(
            (a, b) =>
              (b.resultsTimestamp ? b.resultsTimestamp : b.timestamp) -
              (a.resultsTimestamp ? a.resultsTimestamp : a.timestamp),
          );

          if (allPolls?.length > 0) {
            allPolls.slice(1).forEach((outdatedPoll) => {
              localStorage.setItem(
                `${outdatedPoll.id}${outdatedPoll.showResults ? '_results' : ''}`,
                true,
              );
            });
            setCustomPoll(allPolls[0]);

            const storageRef = firebase.storage().ref();
            storageRef
              .child(`customPolls/${allPolls[0].id}`)
              .getDownloadURL()
              .then((url) => {
                setImageUrl(url);
              });
          } else {
            setCustomPoll(false);
          }
        }
      });
  }, []);

  const handleClosePoll = useCallback((idString) => {
    localStorage.setItem(idString, true);
    setCustomPoll(false);
  }, []);

  const submitPoll = useCallback(
    (pollId, optionId) => {
      const ref = database.ref(`customPolls/${pollId}/results`);
      ref.push(optionId);
      handleClosePoll(pollId);
    },
    [handleClosePoll],
  );

  useEffect(() => {
    const visitorIdUpdateHandler = () => {
      setVisitorId(localStorage.getItem('visitorID_nov_24'));
    };

    window.addEventListener('visitorIdUpdate', visitorIdUpdateHandler);

    return () => {
      window.removeEventListener('visitorIdUpdate', visitorIdUpdateHandler);
    };
  }, []);

  const checkPollStatus = useCallback(() => {
    if (eventInfo && eventInfo.polls !== undefined) {
      const pollArray = Object.keys(eventInfo.polls)
        .map((x) => ({
          id: x,
          timestamp: eventInfo.polls[x].timestamp,
          url: eventInfo.polls[x].url,
        }))
        .filter((x) => !localStorage.getItem(x.id) && Date.now() - x.timestamp < 200000);

      if (pollArray.length > 0) {
        setForm(pollArray[0]);
      } else {
        setForm(false);
      }
    }
  }, [eventInfo]);

  useEffect(() => {
    firebase
      .database()
      .ref(`events/${eventId}`)
      .on('value', (snapshot) => {
        setEventInfo(snapshot.val());
      });
  }, [eventId]);

  useEffect(() => {
    setShow(!!form);
  }, [form]);

  useEffect(() => {
    checkPollStatus();
  }, [eventInfo, checkPollStatus]);

  const handleCloseIframe = useCallback(() => {
    localStorage.setItem(form.id, true);
    setForm(false);
    checkPollStatus();
  }, [form, checkPollStatus]);

  if (customPoll && form) {
    const customTimestamp = customPoll.showResults
      ? customPoll.resultsTimestamp
      : customPoll.timestamp;

    if (customTimestamp > form.timestamp) {
      localStorage.setItem(form.id, true);
      setForm(false);
    } else {
      localStorage.setItem(`${customPoll.id}${customPoll.showResults ? '_results' : ''}`, true);
      setCustomPoll(false);
    }
  }

  return (
    <>
      {customPoll?.showResults ? (
        <CustomPollResults poll={customPoll} visitorId={visitorId} handleClose={handleClosePoll} />
      ) : (
        <CustomPoll
          poll={customPoll}
          image={image}
          visitorId={visitorId}
          handleClose={handleClosePoll}
          submitPoll={submitPoll}
        />
      )}
      <Poll handleClose={handleCloseIframe} show={show} form={form} />
    </>
  );
}

export default CustomPollContainer;
