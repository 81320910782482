import React from 'react';
import { Col, Row } from 'react-bootstrap';
import LoginForm from '../Components/Forms/LoginForm';

const LoginPage = () => {
  document.title = 'Derma Webinar -  Login';
  return (
    <div className="container page-container">
      <div className="login-page">
        <Row className="home-row">

          <Col lg={12} className="px-0">
            <h2 className="login-title form-header">Please, sign in to your account</h2>
            <LoginForm />
            <hr />

            <div className="form-buttons-container forgotten-password-container">
              <p className="float-left main-form-text">If you have forgotten your password:</p>
              <a href="/forgotten-password">
                <button
                  id="btnSignUp"
                  className="btn btn-sm btn-primary text-white float-right"
                  type="button"
                >
                  Forgotten password
                </button>
              </a>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default LoginPage;
