import React, { useState, useEffect } from 'react';
import firebase from 'firebase/app';
import 'firebase/database';
import { Button } from 'react-bootstrap';
import PollCard from './PollCard';

const CustomPolls = () => {
  const [customPolls, setCustomPolls] = useState([]);

  useEffect(() => {
    firebase
      .database()
      .ref('customPolls')
      .on('value', (snapshot) => {
        if (snapshot.val()) {
          const pollArray = Object.keys(snapshot.val()).map((id) => ({
            id,
            ...snapshot.val()[id],
          }));

          setCustomPolls(pollArray.filter((poll) => poll.deleted === undefined));
        }
      });
  }, []);

  return (
    <div>
      <div className="text-right pt-4">
        <Button href="/admin/add-custom-poll" className="btn btn-success">
          Create custom poll
        </Button>
      </div>
      {customPolls.map((poll) => (
        <PollCard poll={poll} />
      ))}
    </div>
  );
};

export default CustomPolls;
