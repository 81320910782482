import React, { useEffect, useState } from 'react';
import app from './FirebaseApp';

export const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(undefined);
  const [showThanks, setShowThanks] = useState(false);

  function toggleShowThanks(thanks) {
    setShowThanks(thanks);
  }

  useEffect(() => {
    app.auth().onAuthStateChanged(setCurrentUser);
  }, []);

  return (
    <AuthContext.Provider value={{ currentUser, showThanks, toggleShowThanks }}>
      {children}
    </AuthContext.Provider>
  );
};
