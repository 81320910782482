import React from 'react';
import 'firebase/database';
import { Modal, Row, Col } from 'react-bootstrap';
import Logo from '../assets/images/leo_logo.png';

function CustomPoll({ poll, visitorId, image, handleClose, submitPoll }) {
  return poll && visitorId ? (
    <Modal
      show
      onHide={() => handleClose(poll.id)}
      keyboard={false}
      size="lg"
      className="custom-poll"
    >
      <Modal.Body className="p-0">
        <Row className="p-0 mx-0">
          {image && (
            <Col md={6} className="p-0 relative poll-image">
              {' '}
              <img src={image} className="w-100" alt="poll" />
              <img src={Logo} className="poll-logo" alt="leo logo" width="50" />
            </Col>
          )}
          <Col
            md={image ? 6 : 12}
            className="bg-success p-0 d-flex flex-column justify-content-center px-4"
          >
            <h3 className="poll-title">Poll</h3>
            <p className="poll-option">{poll.question}</p>
            <p className="poll-option">Please click on one of the answers below:</p>
            <ol className="text-white alpha-bracket-list pl-0" type="a">
              {poll.options.map((option) => (
                <li className="mb-3 poll-answer" onClick={() => submitPoll(poll.id, option.id)}>
                  {option.value}
                </li>
              ))}
            </ol>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  ) : null;
}

export default CustomPoll;
