/* eslint-disable no-restricted-globals */
import React from 'react';
import firebase from 'firebase/app';
import 'firebase/database';
import { Badge, Button } from 'react-bootstrap';
// import { Button } from 'react-bootstrap';
// import { getCollection } from '../../../Functions/FirestoreFunctions';

function calculateValuePercentages(inputObject) {
  const valueCounts = {};
  const totalValues = Object.values(inputObject).length;

  Object.values(inputObject).forEach((value) => {
    valueCounts[value] = (valueCounts[value] || 0) + 1;
  });

  // Calculate the percentages
  const percentageObject = {};
  if (valueCounts) {
    Object.keys(valueCounts).forEach((value) => {
      if (Object.prototype.hasOwnProperty.call(valueCounts, value)) {
        const percentage = ((valueCounts[value] / totalValues) * 100).toFixed(1);
        percentageObject[value] = parseFloat(percentage);
      }
    });
  }

  return percentageObject;
}

const CustomPolls = ({ poll }) => {
  const database = firebase.database();
  const ref = database.ref(`customPolls/${poll.id}`);

  function updatePoll(update) {
    ref.update(update);
  }

  function renderStatusBadge() {
    switch (poll.status) {
      case 'live':
        return <Badge className="badge badge-success poll-status live">Live</Badge>;
      case 'finished':
        return <Badge className="badge badge-warning poll-status finished">Finished</Badge>;
      default:
        return <Badge className="badge badge-dark poll-status">Not Live</Badge>;
    }
  }

  function renderActionButton() {
    switch (poll.status) {
      case 'live':
        return (
          <Button
            className="btn btn-sm btn-warning text-white ml-3"
            onClick={() =>
              updatePoll({
                status: 'finished',
              })
            }
          >
            Stop Poll
          </Button>
        );
      case 'finished':
        return (
          <Button
            className="btn btn-sm btn-success ml-3"
            onClick={() =>
              updatePoll({
                showResults: true,
                resultsTimestamp: Date.now(),
              })
            }
          >
            Show Results
          </Button>
        );
      default:
        return (
          <Button
            className="btn btn-sm btn-success ml-3"
            onClick={() =>
              updatePoll({
                status: 'live',
                timestamp: Date.now(),
              })
            }
          >
            Activate Poll
          </Button>
        );
    }
  }

  const results = poll.results ? calculateValuePercentages(poll.results) : undefined;

  return (
    <div className="w-100 d-flex flex-column border rounded my-4 shadow-sm">
      <div className="d-flex justify-content-between bg-secondary py-2 px-4">
        <h3>{poll.question}</h3>

        <h4 className="mb-0 d-flex align-items-center">{renderStatusBadge()}</h4>
      </div>
      <div className="d-flex justify-content-between p-4 align-items-center">
        <div className="">
          <div className="d-flex ">
            <p className="mb-0">
              <b>Options:</b>
            </p>

            <ol className="mb-0 poll-options-list">
              {poll.options.map((option) => (
                <li className="d-flex justify-content-between">
                  {option.value}:{' '}
                  <div className="ml-5 text-left">
                    {results && results[option.id] ? results[option.id] : 0}% (
                    {poll.results
                      ? Object.values(poll.results).filter((answer) => answer === option.id).length
                      : 0}
                    )
                  </div>
                </li>
              ))}
            </ol>
          </div>
          <div className="d-flex mt-3">
            <p className="mb-0">
              <b>Total responses:</b>
              <span className="d-block">{poll.results && Object.keys(poll.results).length}</span>
            </p>
          </div>
        </div>
        <div>
          {renderActionButton()}

          <Button
            className="btn btn-sm btn-danger ml-3"
            onClick={() => {
              const check = confirm('Are you sure you want to delete?');
              if (check) {
                updatePoll({
                  deleted: true,
                });
              }
            }}
          >
            Delete Poll
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CustomPolls;
