import React, { useState, useEffect, useContext } from 'react';
import 'firebase/database';
import { Button } from 'react-bootstrap';
import QuestionModal from './Modals/QuestionModal';
import { AuthContext } from '../Auth/Auth';
// import Navbar from './Navbar';
// import { Nav } from 'react-bootstrap';
// import Enstilar from '../assets/docs/EU common SmPC - Enstilar cutaneous foam.pdf';
// import Adtralza from '../assets/docs/Adtralza SmPC.pdf';
// import PI from '../assets/docs/uk-altralza-enstilar-prescribing-information v3[68].pdf';

import { getDocInfo } from '../Functions/FirestoreFunctions';

import app from '../Auth/FirebaseApp';

function Header() {
  const [questionOpen, setQuestionOpen] = useState(false);
  const [userInfo, setUserInfo] = useState(undefined);
  const eventId = 'eG9QFc7l6BLQefdxSmjK';

  const { currentUser } = useContext(AuthContext);

  useEffect(() => {
    if (currentUser && currentUser.uid !== undefined) {
      getDocInfo('users', currentUser?.uid).then((result) => {
        setUserInfo(result);
      });
    }
  }, [currentUser]);

  return (
    <>
      <QuestionModal
        open={questionOpen}
        eventId={eventId}
        handleClose={() => {
          setQuestionOpen(false);
        }}
      />
      <div className="header">
        <p className="header-text">This webinar has been organised and funded by LEO Pharma.</p>
        <div>
          <Button
            className="mx-1 header-button-text"
            variant="success"
            onClick={() => setQuestionOpen(true)}
          >
            Ask Question
          </Button>
          {/* <Button
          target="_blank"
          className="mx-1"
          variant="success"
          href="https://www.ema.europa.eu/en/documents/product-information/protopic-epar-product-information_en.pdf"
        >
          Protopic<sup>&reg;</sup> (tacrolimus)
        </Button>
        <Button target="_blank" className="mx-1" variant="success" href={creamPDF}>
          Fucidin<sup>&reg;</sup> cream (fusidic acid)
        </Button>
        <Button target="_blank" className="mx-1" variant="success" href={ointmentPDF}>
          Fucidin<sup>&reg;</sup> ointment (sodium fusidate)
        </Button>
        */}
          {/* {(window.location.pathname === '' || window.location.pathname === '/') && (
            <Button
              target="_blank"
              className="mx-1 header-button-text"
              variant="success"
              href={Adtralza}
            >
              Adtralza<sup>&reg;</sup> <span style={{ color: '#000' }}>▼</span>
              <br />
              (tralokinumab) - EU SmPC
            </Button>
          )}
          {(window.location.pathname === '' || window.location.pathname === '/') && (
            <Button
              target="_blank"
              className="mx-1 header-button-text"
              variant="success"
              href={Enstilar}
            >
              Enstilar<sup>&reg;</sup> (calcipotriene and betamethasone
              <br /> dipropionate) - EU SmPC
            </Button>
          )}
          {(window.location.pathname === '' || window.location.pathname === '/') && (
            <Button target="_blank" className="mx-1 header-button-text" variant="success" href={PI}>
              UK Prescribing
              <br /> Information
            </Button>
          )} */}
          {userInfo && userInfo.role === 'admin' && (
            <>
              <Button variant="success" href="/admin" className="mx-1">
                Admin
              </Button>
            </>
          )}

          {userInfo && (userInfo.role === 'admin' || userInfo.role === 'reporting') && (
            <>
              {!(window.location.pathname === '' || window.location.pathname === '/') && (
                <Button variant="warning" href="/" className="mx-1 text-white ">
                  Webinar
                </Button>
              )}
              <Button variant="warning" href="/speaker-questions" className="mx-1 text-white ">
                Approved Questions
              </Button>
              <Button variant="warning" href="/questions-admin" className="mx-1 text-white ">
                Filter Questions
              </Button>
              <Button
                variant="success"
                href="#"
                onClick={() => {
                  app.auth().signOut();
                  window.location.reload(false);
                }}
                className="mx-1"
              >
                Logout
              </Button>
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default Header;
