import React, { useState, useEffect, useContext, useCallback } from 'react';
import firebase from 'firebase/app';
import 'firebase/database';
import { Button, Modal } from 'react-bootstrap';
import { isQuestionnaireForUser } from '../Functions/RealtimeFunctions';
import { AuthContext } from '../Auth/Auth';
import { getDocInfo } from '../Functions/FirestoreFunctions';

function QuestionnaireIframe() {
  const [form, setForm] = useState(false);
  const [eventsInfo, setEventsInfo] = useState(false);
  const [eventId, setEventId] = useState(false);
  const { currentUser } = useContext(AuthContext);

  useEffect(() => {
    if (currentUser) {
      firebase
        .database()
        .ref('events/')
        .on('value', (snapshot) => {
          setEventsInfo(snapshot.val());
        });
    }
  }, [currentUser]);

  useEffect(() => {
    if (eventsInfo) {
      isQuestionnaireForUser(currentUser.uid, eventsInfo).then((events) => {
        if (events.length > 0) {
          getDocInfo('events', events[0]).then((event) => {
            setEventId(event.id);
            if (event.feedbackForm) {
              setForm(event.feedbackForm);
            }
          });
        } else {
          setForm(false);
        }
      });
    }
  }, [eventsInfo, currentUser]);

  // Using useCallback to memoize the function
  const handleClose = useCallback(() => {
    if (eventId && currentUser) {
      firebase
        .database()
        .ref(`events/${eventId}/user-record/${currentUser.uid}/showQuestionnaire`)
        .set(false);
    }
  }, [eventId, currentUser]);

  return form && form !== 'tbc' ? (
    <Modal show={!!form} onHide={handleClose} keyboard={false} size="lg">
      <Modal.Header>
        <Modal.Title>Your notifications</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {form && <iframe src={form} title="Feedback Form" className="modal-iframe" />}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          {' '}
        </Button>
      </Modal.Footer>
    </Modal>
  ) : null;
}

export default QuestionnaireIframe;
