/* eslint-disable max-len */
import React, { useState } from 'react';
import { Button, Modal, Row, Col } from 'react-bootstrap';
import Leo from '../../assets/images/leo.png';
import modalHeader from '../../assets/images/dermx-2024-modal-header.png';
import modalBackground from '../../assets/images/dermx-2024-modal-bg.png';

const DetailsPane = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <div className="animation__details d-flex flex-column justify-content-center align-items-center">
        <p className="mt-1 mb-1 foyer-header">
          Welcome to{' '}
          <strong>
            DERMx 2024
            <br />
            <span className="larger-text">
              What’s new in dermatology?<br />
              Meeting patient needs in a<br />
              changing world
            </span>
          </strong>
        </p>
        {/* <p className="mb-1 foyer-date">
          Presentation date: {moment(details?.start).tz('Europe/London').format('DD/MM/YYYY')}{' '}
          <br />
          {moment(details?.start).tz('Europe/London').format('HH:mm')} –{' '}
          {moment(details?.end).tz('Europe/London').format('HH:mm')}
        </p> */}
        <Button
          id="btnDeactivate"
          variant="secondary"
          className="foyer-btn"
          onClick={handleShow}
          type="button"
        >
          Event Details
        </Button>
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        keyboard={false}
        size="xl"
        className="eventdetails__modal"
      >
        {/* <Modal.Header closeButton>
          <Modal.Title className="text-center">{details?.title}</Modal.Title>
        </Modal.Header> */}
        <Modal.Body
          className="text-left details-modal px-0 pt-0"
          style={{ fontSize: '18px', color: 'rgb(40, 98, 154)' }}
        >
          <div className="sessionModalBanner" style={{ background: `url(${modalBackground})` }}>
            <div>
              <p className="sessionModalSubtitle">AGENDA</p>
              <p className="sessionModalTitle">DERMx 2024</p>
              <p className="sessionModalHeader">
                What’s new in dermatology?
                <br />
                Meeting patient needs in a<br />
                changing world
              </p>
            </div>
            <div>
              <img src={modalHeader} className="sessionModalImage" alt="Woman smiling" />
            </div>
          </div>
          <div className="pt-4 px-3 px-lg-5">
            <p className="sessionAgendaBody">
              Join us for our November webinar to hear from our international faculty!
            </p>
            <p className="sessionAgendaBody mb-4">
              Please see below the topics that will be discussed and corresponding speakers.
              Throughout the webinar, there will also be opportunities to interact with the panel
              for an enriched educational experience.
            </p>
            <table className="events-table mb-5">
              <tr>
                <th className="col1">Duration</th>
                <th className="col2">Topic</th>
                <th className="col3">Speaker</th>
              </tr>
              <tr>
                <td>5 min</td>
                <td>Welcome and introductions</td>
                <td>Dr Linda Stein Gold</td>
              </tr>
              <tr>
                <td>15 min</td>
                <td>How does your patients’ skin respond to climate changes?</td>
                <td>Prof. Claudia Traidl-Hoffmann &amp; Assoc. Prof. Wenhui Wang</td>
              </tr>
              <tr>
                <td>15 min</td>
                <td>
                  Adherence in an era of multiple treatment options &ndash; does method of administration
                  matter?
                </td>
                <td>Prof. Steve Feldman</td>
              </tr>
              <tr>
                <td>15 min</td>
                <td>
                  How can we maximize accessibility to high-quality psoriasis care? A Global
                  Healthcare Study
                </td>
                <td>PD Dr Julia-Tatjana Maul</td>
              </tr>
              <tr>
                <td>10 min</td>
                <td>Q&amp;A</td>
                <td>All</td>
              </tr>
            </table>

            <div className="agendaContainer">
              <p className="sessionAgendaBody text-center">
                Our webinar agenda will be repeated across three sessions during week commencing
                <br className="d-none d-xl-block" />
                11<sup>th</sup> November; we hope that you will be able to attend the session that
                best suits you!
              </p>
              <Row className="mtz-4">
                <Col xl={12}>
                  <Row className="px-4">
                    <Col lg={6} xl={4} className="mb-3 px-lg-2">
                      <p className="sessionTitle">Session 1</p>
                      <p className="sessionDate mb-4">
                        Tuesday 12<sup>th</sup> November:
                      </p>
                      <div className="clock_container">
                        <div className="clock_item seperator">
                          <p className="clock_info">
                            17.00
                            <br />
                            New York
                            <br />
                            (EST)
                          </p>
                        </div>
                        <div className="clock_item seperator">
                          <p className="clock_info">
                            19.00
                            <br />
                            São Paulo
                            <br />
                            (BRT)
                          </p>
                        </div>
                        <div className="clock_item">
                          <p className="clock_info">
                            23.00
                            <br />
                            Copenhagen
                            <br />
                            (CET)
                          </p>
                        </div>
                      </div>
                    </Col>
                    <Col lg={6} xl={4} className="mb-3 px-lg-2 px-0">
                      <p className="sessionTitle">Session 2</p>
                      <p className="sessionDate mb-4">
                        Wednesday 13<sup>th</sup> November:
                      </p>
                      <div className="clock_container">
                        <div className="clock_item seperator">
                          <p className="clock_info">
                            19.00
                            <br />
                            Copenhagen
                            <br />
                            (CET)
                          </p>
                        </div>
                        <div className="clock_item seperator">
                          <p className="clock_info">
                            21.00
                            <br />
                            Riyadh
                            <br />
                            (AST)
                          </p>
                        </div>
                        <div className="clock_item">
                          <p className="clock_info">
                            22.00
                            <br />
                            Dubai
                            <br />
                            (GST)
                          </p>
                        </div>
                      </div>
                    </Col>
                    <Col lg={6} xl={4} className="mb-3 px-lg-2 px-0">
                      <p className="sessionTitle">Session 3</p>
                      <p className="sessionDate mb-4">
                        Friday 15<sup>th</sup> November:
                      </p>
                      <div className="clock_container">
                        <div className="clock_item seperator">
                          <p className="clock_info">
                            11.00
                            <br />
                            Copenhagen
                            <br />
                            (CET)
                          </p>
                        </div>
                        <div className="clock_item seperator">
                          <p className="clock_info">
                            18.00
                            <br />
                            Shanghai
                            <br />
                            (CST)
                          </p>
                        </div>
                        <div className="clock_item">
                          <p className="clock_info">
                            21.00
                            <br />
                            Sydney
                            <br />
                            (AEDT)
                          </p>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>

            <Row className="mt-5">
              <Col md={2}>
                <img src={Leo} alt="leo logo" width="115" height="92" />
              </Col>
              <Col md={10} className="text-right">
                <p className="sessionAgendaBody sessionAgendaBodySm">
                  This agenda is for the invited person only and should not be shared with others.
                </p>
                <small className="footer-disclaimer">
                  © September 2024 LEO Pharma A/S. All rights reserved.
                  <br />
                  All LEO Pharma Trademarks mentioned belong to LEO Pharma A/S
                  <br />
                </small>
                <small className="footer-disclaimer">MAT-76346 | September 2024</small>
              </Col>
            </Row>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            id="btnDeactivate"
            variant="secondary"
            className="leo-button bd-secondary bg-white"
            onClick={handleClose}
            type="button"
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DetailsPane;
