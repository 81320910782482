import React, { useState, useCallback } from 'react';
import { Row, Button } from 'react-bootstrap';
import { logInUser } from '../../Functions/AuthFunctions';
import TextField from './FormFields/TextField';

const LoginForm = () => {
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    HCP: false,
    persist: false,
  });
  document.body.classList.remove('live-event');
  function updateFormData(update) {
    const newFormData = { ...formData };
    newFormData[update.field] = update.value;
    setFormData(newFormData);
  }

  const [error, setError] = useState(undefined);

  const handleSignIn = useCallback(async () => {
    logInUser(formData).then((res) => {
      if (res.message) {
        setError(res.message);
      } else {
        // const redirectUrl = sessionStorage.getItem('redirectUrl');
        // sessionStorage.removeItem('redirectUrl', window.location.href);
        window.location.href = '/';
      }
    });
  });

  return (
    <>
      <div className="form-group login-form">
        <Row>
          <div className="col-md-12 mb-3">{error && <li className="text-danger">{error}</li>}</div>
          <TextField
            type="email"
            size={12}
            field="email"
            label="Email"
            formData={formData}
            updateFormData={updateFormData}
          />
          <TextField
            type="password"
            size={12}
            field="password"
            label="Password"
            formData={formData}
            updateFormData={updateFormData}
          />
        </Row>
      </div>
      <div className="form-buttons-container mb-5">
        <Button
          id="btnSignUp"
          variant="primary"
          className="btn text-white float-right"
          onClick={handleSignIn}
          type="button"
          size="sm"
        >
          Sign In
        </Button>
      </div>
    </>
  );
};

export default LoginForm;
