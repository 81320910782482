import React, { useState } from 'react';
import firebase from 'firebase/app';
import 'firebase/database';
import { Form, Col, Row, Button, Alert } from 'react-bootstrap';

const ActiveUsers = ({ eventId }) => {
  const [alert, setAlert] = useState(undefined);
  const [link, setLink] = useState('');

  function handleActivatePoll() {
    firebase.database().ref(`events/${eventId}/polls`).push({
      url: link,
      timestamp: Date.now(),
    }).then(() => {
      setAlert({ text: 'Poll activated', type: 'success' });
      setTimeout(() => {
        setAlert(undefined);
      }, 3000);
      setLink('');
    });
  }

  return (
    <Form.Group className="mt-5">
      {alert && (
      <Alert variant={alert.type}>
        {alert.text}
      </Alert>
      )}
      <Row>
        <Col md={6} className="text-center">
          <h3>In Event Poll</h3>
          <Form.Label>Poll URL</Form.Label>
          <Form.Control
            type="text"
            onChange={(e) => { setLink(e.target.value); }}
            value={link}
            className="form-control mb-2"
            placeholder="Poll URL"
          />
          <Button onClick={handleActivatePoll} className="mt-3" variant="success">Activate Poll</Button>
        </Col>
      </Row>
    </Form.Group>
  );
};

export default ActiveUsers;
