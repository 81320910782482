export function validateEventForm(formData) {
  if (formData === undefined) {
    return [];
  }

  const errors = [];

  if (formData.title.length < 1) {
    errors.push('The title field must be filled');
  }

  if (formData.location.length < 1) {
    errors.push('The location field must be filled');
  }

  if (formData.faculty.length < 1) {
    errors.push('The faculty field must be filled');
  }

  if (formData.iFrameURL.length < 1) {
    errors.push('The iframe URL field must be filled');
  }
  if (formData.description.length < 1) {
    errors.push('The description field must be filled');
  }
  if (formData.agenda.length < 1) {
    errors.push('The agenda field must be filled');
  }

  return errors;
}

export function getEmptyEventFormData(now) {
  return {
    title: '',
    location: '',
    faculty: '',
    iFrameURL: '',
    description: '',
    agenda: '',
    start: now,
    end: now,
    outsideFoyerImage: '',
    foyerImage: '',
    iFrameBackground: '',
  };
}

export function getCSV(data) {
  const rows = [
    [
      '#',
      'Title',
      'First Name',
      'Last Name',
      'Job Title',
      'Institution',
      'Work Location',
      'Country',
      'Phone Number',
      'Email',
      'Communications',
      'Online/In-Person',
      'Dietary Requirements',
      'Food allergies',
    ],
  ];

  data.forEach((user, i) => {
    rows.push([
      i + 1,
      user.title,
      user.firstName,
      user.lastName,
      user.jobTitle,
      user.institution,
      user.workLocation,
      user.country,
      user.phoneNumber,
      user.email,
      user.communications ? 'Yes' : 'No',
      user.joinType === 'online' ? 'Online' : 'In-person',
      user.dietary,
      user.allergies,
    ]);
  });

  return (rows);
}

export default validateEventForm();
