/* eslint-disable react/no-danger */
import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import TitleBanner from '../Components/TitleBanner';
import { getDocInfo } from '../Functions/FirestoreFunctions';

// const moment = require('moment');

const Admin = () => {
  const [event, setEvent] = useState(undefined);
  const eventId = 'eG9QFc7l6BLQefdxSmjK';

  async function setEventData() {
    const data = await getDocInfo('events', eventId);
    setEvent(data);
  }

  useEffect(() => {
    setEventData();
  }, []);

  return event ? (
    <div className="container page-container">
      <TitleBanner title={event.title} />
      <h3>Details:</h3>
      <p><strong>Description</strong></p>
      <p
        aria-label={event.title}
        dangerouslySetInnerHTML={{
          __html: event.description?.replace(/(\d)(st|nd|rd|th)/g, '$1<sup>$2</sup>'),
        }}
      />
      <p><strong>IFrame link:</strong></p>
      <p>{event.iFrameURL}</p>
      <div className="d-flex justify-content-center">
        <Button className="btn-success  text-white mr-1" href={`/admin/admin-event-dashboard/${eventId}`}>Polls</Button>
        <Button className="btn-success  text-white mr-1" href={`/admin/event-report/${eventId}`}>Event report</Button>
        <Button className="btn-success  text-white mr-1" href={`/admin/edit-event/${eventId}`}>Edit event</Button>
      </div>
    </div>

  ) : null;
};

export default Admin;
