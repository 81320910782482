import React from 'react';
import 'firebase/database';
import { Modal, Row, Col } from 'react-bootstrap';
import { Chart } from 'react-google-charts';

function CustomPoll({ poll, visitorId, handleClose }) {
  function getPieChartData(selectedPoll) {
    if (selectedPoll.results) {
      const data = selectedPoll.options.map((option) => {
        const count = Object.values(selectedPoll.results).filter(
          (pollResult) => pollResult === option.id,
        ).length;
        return [option.value, count];
      });

      return [['Option', 'Count'], ...data];
    }
    return [];
  }

  return poll && visitorId ? (
    <Modal
      show
      onHide={() => handleClose(`${poll.id}_results`)}
      keyboard={false}
      size="lg"
      className="custom-poll"
    >
      <Modal.Body className="p-0">
        <Row className="p-0">
          <Col md={8} className="p-0 relative">
            <Chart
              chartType="PieChart"
              data={getPieChartData(poll)}
              options={{
                title: 'Results',
                slices: {
                  0: { color: '#40A57E' },
                  1: { color: '#dc8e00' },
                  2: { color: '#204131' },
                  4: { color: '#6cc6a3' },
                  3: { color: '#a96d00' },
                  5: { color: '#ffaa10' },
                  7: { color: '#ffbc43' },
                  6: { color: '#399370' },
                },
              }}
              width="100%"
              height="400px"
            />
          </Col>
          <Col md={4} className="bg-success p-0 d-flex flex-column justify-content-center px-4">
            <h3 className="poll-title">Results</h3>
            <p className="poll-option">{poll.question}</p>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  ) : null;
}

export default CustomPoll;
