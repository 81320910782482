import React from 'react';
import foyer from '../../assets/animation/foyer.png';
import FoyerImage from './FoyerImage';
import Door from './Door';

function Foyer({ foyerImage, setStage }) {
  return (
    <>
      <img src={foyer} alt="foregound" className="animation__foyer" />
      <FoyerImage foyerImage={foyerImage} setStage={setStage} />
      <Door orientation="left" room="auditorium" />
      <Door orientation="right" room="auditorium" />
    </>
  );
}

export default Foyer;
