import React from 'react';
import LoaderJS from 'react-loader-spinner';

const Loader = () => (
  <div className="d-flex justify-content-center mb-4">
    <LoaderJS type="ThreeDots" color="#e02429" height={100} width={100} />
  </div>
);

export default Loader;
