import { useEffect } from 'react';

import '../assets/lib/cookieconsent.css';
import '../assets/lib/cookieconsent';

export default function CookieConsent() {
  useEffect(() => {
    const cc = window.initCookieConsent();

    cc.run({
      autorun: true,
      current_lang: 'en',
      theme_css: '<path-to-cookieconsent.css>',
      autoclear_cookies: true,
      page_scripts: true,
      languages: {
        en: {
          consent_modal: {
            title: 'This site uses cookies',
            description:
              'This website uses tracking cookies to understand how you interact with it, which will be set only upon approval. <a aria-label="Cookie policy" class="cc-link" href="/cookie-policy">Read more</a>',
            primary_btn: {
              text: 'Accept',
              role: 'accept_all', // 'accept_selected' or 'accept_all'
            },
            secondary_btn: {
              text: 'Settings',
              role: 'settings', // 'settings' or 'accept_necessary'
            },
          },
          settings_modal: {
            title: 'Cookie preferences',
            save_settings_btn: 'Save settings',
            accept_all_btn: 'Accept',
            reject_all_btn: 'Reject', // optional, [v.2.5.0 +]
            cookie_table_headers: [
              {
                col1: 'Name',
              },
              {
                col2: 'Domain',
              },
              {
                col3: 'Type',
              },
              {
                col4: 'Description',
              },
              {
                col5: 'Duration',
              },
            ],
            blocks: [
              {
                title: 'Analytics cookies',
                description:
                  'These cookies collect information about how you use the website, which pages you visited and which links you clicked on. All of the data is anonymized and cannot be used to identify you.',
                toggle: {
                  value: 'analytics',
                  enabled: true,
                  readonly: false,
                },
                cookie_table: [
                  {
                    col1: '_ga_23GV3F4N3W',
                    col2: 'TBC',
                    col3: 'Analytics',
                    col4: 'This cookie is installed by Google Analytics.',
                    col5: '2 years',
                    is_regex: true,
                  },
                  {
                    col1: '_ga',
                    col2: 'TBC',
                    col3: 'Analytics',
                    col4: "The _ga cookie, installed by Google Analytics, calculates visitor, session and campaign data and also keeps track of site usage for the site's analytics report. The cookie stores information anonymously and assigns a randomly generated number to recognize unique visitors.",
                    col5: '2 years',
                  },
                ],
              },
            ],
          },
        },
      },
    });
  }, []);

  return null;
}
