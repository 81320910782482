import React, { useState, useCallback } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import { addQuestion } from '../../Functions/RealtimeFunctions';
import 'firebase/database';

function QuestionModal({ open, eventId, handleClose }) {
  const [question, setQuestion] = useState('');

  // Memoize the handleAsk function
  const handleAsk = useCallback(() => {
    addQuestion(question, eventId, localStorage.getItem('visitorID_nov_24')).then(() => {
      handleClose();
      setQuestion('');
    });
  }, [question, eventId, handleClose]);

  // Memoize the handleClose function if necessary
  const handleCloseModal = useCallback(() => {
    handleClose();
  }, [handleClose]);

  return (
    <Modal className="details-modal" show={open} onHide={handleCloseModal} keyboard={false}>
      <Modal.Header>
        <Modal.Title>Ask the presenter a question</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
            <Form.Control
              value={question}
              onChange={(e) => setQuestion(e.target.value)}
              as="textarea"
              rows={3}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleCloseModal}>
          Close
        </Button>
        <Button variant="success" onClick={handleAsk}>
          Ask question
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default QuestionModal;
