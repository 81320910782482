import React, { useEffect, useState } from 'react';
import firebase from 'firebase/app';
import { Row, Col, Container, Button } from 'react-bootstrap';
// import firebase from 'firebase/app';
import 'firebase/storage';

const CreateEvent = ({ history }) => {
  const [question, setQuestion] = useState(undefined);
  const [inputValues, setInputValues] = useState(['', '']);
  const [image, setImage] = useState(undefined);
  const [errorBag, setErrorBag] = useState([]);
  const [preview, setPreview] = useState('');

  useEffect(() => {
    if (image) {
      const objectUrl = URL.createObjectURL(image);
      setPreview(objectUrl);
    } else {
      setPreview(undefined);
    }
  }, [image]);

  const onSelectFile = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setImage(undefined);
      return;
    }

    // I've kept this example simple by using the first image instead of multiple
    setImage(e.target.files[0]);
  };

  console.log(image);

  const handleInputChange = (index, event) => {
    const newInputValues = [...inputValues];
    newInputValues[index] = event.target.value;
    setInputValues(newInputValues);
  };

  const addInput = () => {
    setInputValues([...inputValues, '']);
  };

  const removeInput = (index) => {
    const newInputValues = [...inputValues];
    newInputValues.splice(index, 1);
    setInputValues(newInputValues);
  };

  function validateInputValues() {
    const nonEmptyInputs = inputValues.filter((input) => input.length > 0);
    return nonEmptyInputs.length >= 2;
  }

  function validatePoll() {
    const errors = [];
    if (!validateInputValues()) {
      errors.push('You must provide at least 2 options');
    }

    if (question === undefined || question.length === 0) {
      errors.push('You must provide a question');
    }

    return errors;
  }

  async function createPollWithImage() {
    const options = inputValues.map((input, index) => ({ value: input, id: index }));
    const res = await firebase.database().ref('customPolls').push({ question, options });

    if (image) {
      const foyerRef = firebase.storage().ref().child(`customPolls/${res.getKey()}`);
      await foyerRef.put(image);
    }
  }

  function createPoll() {
    const errors = validatePoll();
    if (errors.length === 0) {
      setErrorBag([]);
      try {
        createPollWithImage().then(() => {
          history.push('/admin/admin-event-dashboard/eG9QFc7l6BLQefdxSmjK');
        });
      } catch (e) {
        setErrorBag([e.message]);
      }
    } else {
      setErrorBag(errors);
    }
  }

  return (
    <div className="container page-container">
      <Container>
        <h2 className="mt-5">Create custom poll</h2>
        <div className="form-group">
          <Row>
            <Col md={6}>
              <label htmlFor="titleInput">Question</label>
              <input
                type="text"
                id="titleInput"
                className="form-control mb-2"
                placeholder="Poll question"
                value={question}
                onChange={(e) => {
                  setQuestion(e.target.value);
                }}
              />
            </Col>
            <Col md={6}>
              <label htmlFor="titleInput">Answers</label>

              {inputValues.map((value, index) => (
                <Row className="mb-2">
                  <Col md={11}>
                    <input
                      type="text"
                      id="titleInput"
                      className="form-control"
                      placeholder={`Option ${index + 1}`}
                      value={value}
                      onChange={(e) => handleInputChange(index, e)}
                    />
                  </Col>
                  {index !== 0 && (
                    <Col md={1} className="d-flex justify-content-center align-items-center">
                      <Button
                        type="button"
                        className="btn btn-secondary"
                        onClick={() => removeInput(index)}
                      >
                        x
                      </Button>
                    </Col>
                  )}
                </Row>
              ))}
              <Row className="mb-2">
                <Col className="d-flex justify-content-end" md={11}>
                  <Button type="button" className="btn btn-success" onClick={addInput}>
                    +
                  </Button>
                </Col>
              </Row>
            </Col>
            <Col htmlFor="foyerImage" md={12} className="my-4">
              <label htmlFor="formFile" className="form-label">
                Foyer Image{' '}
              </label>
              <input className="form-control" id="formFile" type="file" onChange={onSelectFile} />
              {image && preview && <img src={preview} alt="upload preview" width="200" />}
            </Col>
          </Row>
        </div>
        <ul>
          {errorBag.map((error) => (
            <li className="text-danger">{error}</li>
          ))}
        </ul>
        <div className="form-buttons-container  text-center">
          <button
            id="btnSignUp"
            className="btn btn-warning text-white "
            onClick={createPoll}
            type="button"
          >
            Create
          </button>
        </div>
      </Container>
    </div>
  );
};

export default CreateEvent;
