import React, { useEffect } from 'react';
import { handleCheckIn } from '../Functions/FirestoreFunctions';

const sessionOne = {
  start: Date.parse('12 Nov 2024 22:00:00 GMT'),
  end: Date.parse('12 Nov 2024 23:30:00 GMT'),
};

const sessionTwo = {
  start: Date.parse('13 Nov 2024 18:00:00 GMT'),
  end: Date.parse('13 Nov 2024 19:30:00 GMT'),
};

const sessionThree = {
  start: Date.parse('15 Nov 2024 10:00:00 GMT'),
  end: Date.parse('15 Nov 2024 11:30:00 GMT'),
};

function checkIn(sessionTag, sessionTimes) {
  const visitorID = localStorage.getItem('visitorID_nov_24');

  if (
    visitorID !== null &&
    Date.now() > sessionTimes.start &&
    Date.now() < sessionTimes.end &&
    (!localStorage.getItem(`${sessionTag}_begun`) ||
      (localStorage.getItem(`${sessionTag}_begun`) &&
        sessionStorage.getItem(`${sessionTag}_begun`)))
  ) {
    sessionStorage.setItem(`${sessionTag}_begun`, true);
    localStorage.setItem(`${sessionTag}_begun`, true);
    window.addEventListener('unload', () => localStorage.removeItem(`${sessionTag}_begun`));
    handleCheckIn(visitorID, sessionTag);
    localStorage.setItem(sessionTag, 'true');
  }
}

const EventTracker = () => {
  useEffect(() => {
    setInterval(() => {
      checkIn('sessionOne', sessionOne);
      checkIn('sessionTwo', sessionTwo);
      checkIn('sessionThree', sessionThree);
    }, 60000);
  }, []);

  return <></>;
};

export default EventTracker;
