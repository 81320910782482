import React from 'react';
import { Container } from 'react-bootstrap';

const TitleBanner = ({ title }) => (
  <Container className="my-5 text-center">
    <h1 className="mb-0">{title}</h1>
  </Container>
);

export default TitleBanner;
