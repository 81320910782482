import React from 'react';
import Player from '@vimeo/player';
// Using exmaple image for now
// TODO: update to use image from event details
function FoyerImage({ foyerImage, setStage }) {
  function gotToAuditorium() {
    const player = new Player('vimeo-player-webinar');
    player.setMuted(false);

    // document
    //   .querySelectorAll('iframe')
    //   .forEach((item) => console.log(item.contentWindow.document.querySelectorAll('button')));

    setStage(2);
  }
  return (
    <a onClick={() => gotToAuditorium(2)} className="animation__foyerimagelink">
      <img
        src={foyerImage}
        alt="info-board"
        className="animation__foyerimage"
        style={{ zIndex: 97 }}
      />
      ;
    </a>
  );
}

export default FoyerImage;
