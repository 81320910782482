import firebase from 'firebase/app';
import app from '../Auth/FirebaseApp';
import 'firebase/firestore';
import 'firebase/storage';
import { updateDoc, createDoc } from './FirestoreFunctions';

export function validateRegistrationForm(formData) {
  const errorBag = [];
  const re = /\S+@\S+\.\S+/;

  if (formData.email !== formData.confirmEmail) {
    errorBag.push('Emails do not match.');
  }

  if (formData.password !== formData.confirmPassword) {
    errorBag.push('Passwords do not match.');
  }

  if (!formData.HCP) {
    errorBag.push('You must confirm that that you are a Healthcare Professional.');
  }

  if (!formData.Privacy) {
    errorBag.push('You must agree with the Privacy Notice to proceed.');
  }

  if (!formData.Terms) {
    errorBag.push('You must agree with the Terms and Conditions to proceed.');
  }

  if (!formData.email) {
    errorBag.push('The Email field is required.');
  }

  if (!re.test(formData.email)) {
    errorBag.push('The Email provided is not valid');
  }

  if (!formData.password) {
    errorBag.push('The Password field is required.');
  }
  if (formData.password.length > 0 && formData.password.length < 6) {
    errorBag.push('Password must be at least 6 characters long');
  }
  if (!formData.title) {
    errorBag.push('The Title field is required.');
  }
  if (!formData.firstName) {
    errorBag.push('The First Name field is required.');
  }
  if (!formData.lastName) {
    errorBag.push('The Last Name field is required.');
  }
  if (!formData.jobTitle) {
    errorBag.push('The Job Title field is required.');
  }
  if (!formData.institution) {
    errorBag.push('The Institution field is required.');
  }
  if (!formData.country) {
    errorBag.push('The Country field is required.');
  }
  if (!formData.city) {
    errorBag.push('The City field is required.');
  }

  return errorBag;
}

export function getEmptyRegistrationForm() {
  return {
    email: '',
    confirmEmail: '',
    password: '',
    confirmPassword: '',
    firstName: '',
    lastName: '',
    title: 'Mr',
    jobTitle: '',
    institution: '',
    workLocation: '',
    country: '',
    phoneNumber: '',
    HCP: false,
    TandC: false,
    signUpToComs: false,
    privacyPolicy: false,
    dietary: '',
    joinType: 'online',
    allergies: '',
  };
}

export function registerUser(formData, appSDK = app) {
  sessionStorage.setItem('freshRegister', true);
  return appSDK
    .auth()
    .createUserWithEmailAndPassword(formData.email, formData.password)
    .then((userRecord) => {
      const { uid } = userRecord.user;
      appSDK.firestore().collection('users').doc(uid).set({
        uid,
        email: formData.email,
        role: 'user',
      })

        .then(() => {
          appSDK.auth().signInWithEmailAndPassword(formData.email, formData.password);
        });
    });
}

export async function logInUser(formData) {
  return firebase
    .auth()
    .setPersistence(firebase.auth.Auth.Persistence.LOCAL)
    .then(() => app.auth().signInWithEmailAndPassword(formData.email, formData.password))
    .catch((e) => e);
}

export function triggerForgotPassword(email) {
  const promise = app.auth().sendPasswordResetEmail(email);
  return promise.catch((e) => e.message);
}

export function validateUpdateUserForm(formData) {
  const errorBag = [];

  if (!formData.title) {
    errorBag.push('The Title field is required.');
  }
  if (!formData.firstName) {
    errorBag.push('The First Name field is required.');
  }
  if (!formData.lastName) {
    errorBag.push('The Last Name field is required.');
  }
  if (!formData.jobTitle) {
    errorBag.push('The Job Title field is required.');
  }
  if (!formData.institution) {
    errorBag.push('The Institution field is required.');
  }
  if (!formData.country) {
    errorBag.push('The Country field is required.');
  }
  if (!formData.city) {
    errorBag.push('The City field is required.');
  }

  return errorBag;
}

export async function updateUser(uid, formData) {
  try {
    if (uid) {
      app.firestore().collection('users').doc(uid).set({
        title: formData.title,
        firstName: formData.firstName,
        lastName: formData.lastName,
        jobTitle: formData.jobTitle,
        institution: formData.institution,
        city: formData.city,
        country: formData.country,
        role: formData.role,
        uid,
      });
    }
  } catch (error) {
    alert(error);
  }
}

export async function addSpeakerImage(file, speakerId) {
  const storageRef = firebase.storage().ref();
  if (file) {
    const foyerRef = storageRef.child(`speakers/${speakerId}`);
    return foyerRef.put(file);
  }
  return null;
}

export async function removeProfilePictureIfExists(speakerId) {
  const storageRef = firebase.storage().ref();
  storageRef.child(`speakers/${speakerId}`).delete();
}

export async function getProfilePicture(speakerId) {
  const storageRef = firebase.storage().ref();
  let res;
  try {
    res = await storageRef.child(`speakers/${speakerId}`).getDownloadURL();
  } catch (e) {
    console.log(e);
  }

  return res;
}

export async function addSpeaker(formData) {
  return createDoc('speakers', { title: formData.title, bio: formData.bio }).then((result) => {
    if (formData.photo) {
      return addSpeakerImage(formData.photo, result);
    }
    return null;
  });
}

export async function updateSpeaker(speakerId, formData) {
  updateDoc('speakers', speakerId, { title: formData.title, bio: formData.bio }).then(() => {
    if (formData.photo) {
      return addSpeakerImage(formData.photo, speakerId);
    }
    return null;
  });
}

export default validateRegistrationForm;
