import React from 'react';
// Using exmaple image for now
// TODO: update to use image from event details
function Webinar({ event, image }) {
  return (
    <>
      <iframe
        src={`${event?.iFrameURL}?muted=0`}
        // src="https://player.vimeo.com/video/783453158?autoplay=1&muted=1"
        title={event?.title}
        className="webinar__container"
        allow="fullscreen"
        style={{
          backgroundImage: `url(${image})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center center',
        }}
        id="vimeo-player-webinar"
        allowFullScreen
      />
      {/* Holding image to be removed when iFrame URL added */}
      {/* <img
        src={image}
        className="webinar__container webinar__container-img"
        alt="The webinar is coming soon"
      /> */}
    </>
  );
}

export default Webinar;
