import React, { useState, useEffect } from 'react';
import 'firebase/database';
import { Table, Container } from 'react-bootstrap';
import Loader from '../Components/Loader';
import TitleBanner from '../Components/TitleBanner';
import { getCollection } from '../Functions/FirestoreFunctions';

const ActiveUsers = () => {
  const [visitors, setVisitors] = useState({});

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getCollection('visitors').then((result) => {
      setVisitors(result.docs);
      setLoading(false);
    });
  }, []);

  return loading ? (
    <Loader />
  ) : (
    <div className="container page-container ">
      <TitleBanner title="Event Report" />
      <Container>
        {visitors ? (
          <Table responsive striped bordered hover className="mt-5">
            <thead className="text-center thead-dark">
              <th>First name</th>
              <th>Last name</th>
              <th>Email</th>
              <th>Country</th>
              <th>Specialty</th>
              <th>Attended Session 1</th>
              <th>Attended Session 2</th>
              <th>Attended Session 3</th>
            </thead>
            <tbody className="text-center">
              {visitors &&
                visitors.map((user) => {
                  const data = user.data();
                  return (
                    <tr>
                      <td className="align-middle">{data.firstName} </td>
                      <td className="align-middle">{data.lastName} </td>
                      <td className="align-middle">{data.email} </td>
                      <td className="align-middle">{data.country}</td>
                      <td className="align-middle">{data.specialty}</td>
                      <td className="align-middle">
                        {data.sessionOne_time ? `${data.sessionOne_time} mins` : 'No'}
                      </td>
                      <td className="align-middle">
                        {data.sessionTwo_time ? `${data.sessionTwo_time} mins` : 'No'}
                      </td>
                      <td className="align-middle">
                        {data.sessionThree_time ? `${data.sessionThree_time} mins` : 'No'}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
        ) : (
          <p className="text-center text-danger">No data available for this event</p>
        )}
      </Container>
    </div>
  );
};

export default ActiveUsers;
