import React from 'react';
import auditorium from '../../assets/animation/auditorium.jpg';

function Auditorium() {
  return (
    <>
      <img src={auditorium} alt="auditorium" className="animation__auditorium" />;
    </>
  );
}

export default Auditorium;
