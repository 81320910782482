import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { AuthContext } from './Auth';

const LoginRoute = ({ component: RouteComponent, ...rest }) => {
  const { currentUser } = useContext(AuthContext);

  function getRedirect() {
    // if (sessionStorage.getItem('redirectUrl')
    // && sessionStorage.getItem('redirectUrl') !== null) {
    //   const redirectUrl = sessionStorage.getItem('redirectUrl');
    //   sessionStorage.removeItem('redirectUrl');
    //   setTimeout(() => { window.location = redirectUrl; }, 1000);
    // }
    return <Redirect to="/" />;
  }

  return (
    <Route
      {...rest}
      render={(routeProps) =>
        !currentUser ? <RouteComponent {...routeProps} /> : getRedirect()
      }
    />
  );
};

export default LoginRoute;
