import React from 'react';
import doorLeft from '../../assets/animation/door-left.png';
import doorRight from '../../assets/animation/door-right.png';
import doorLeft2 from '../../assets/animation/door-r-left.png';
import doorRight2 from '../../assets/animation/door-r-right.png';

function Door({ orientation, room = 'foyer' }) {
  return room === 'foyer' ? (
    orientation === 'left' ? (
      <img src={doorLeft} alt={`door-${orientation}`} className="animation__doorleft" />
    ) : (
      <img src={doorRight} alt={`door-${orientation}`} className="animation__doorright" />
    )
  ) : orientation === 'left' ? (
    <img src={doorLeft2} alt={`door-${orientation}`} className="animation__doorleft2" />
  ) : (
    <img src={doorRight2} alt={`door-${orientation}`} className="animation__doorright2" />
  );
}

export default Door;
