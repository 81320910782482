import React from 'react';

const CookiePolicy = () => {
  document.title = 'Derma Webinar';
  return (
    <div className="container page-container">
      <p>
        <strong>Cookies Policy</strong>
      </p>
      <table className="table">
        <thead>
          <tr>
            <td>
              <p>Cookie</p>
            </td>
            <td>
              <p>Domain</p>
            </td>
            <td>
              <p>Type&nbsp;⇅</p>
            </td>
            <td>
              <p>Description</p>
            </td>
            <td>
              <p>Duration</p>
            </td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <p>_ga_23GV3F4N3W</p>
            </td>
            <td>
              <p>TBC</p>
            </td>
            <td>
              <p>Analytics</p>
            </td>
            <td>
              <p>This cookie is installed by Google Analytics.</p>
            </td>
            <td>
              <p>2 years</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>_ga</p>
            </td>
            <td>
              <p>TBC</p>
            </td>
            <td>
              <p>Analytics</p>
            </td>
            <td>
              <p>
                The _ga cookie, installed by Google Analytics, calculates visitor, session and
                campaign data and also keeps track of site usage for the site&#39;s analytics
                report. The cookie stores information anonymously and assigns a randomly generated
                number to recognize unique visitors.
              </p>
            </td>
            <td>
              <p>2 years</p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default CookiePolicy;
