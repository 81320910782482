import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/analytics';

const app = firebase.initializeApp({
  apiKey: 'AIzaSyC_d98X4Go9H7dWNwnPs5nVhV8_zGu8dM4',
  authDomain: 'derma-june-webinar.firebaseapp.com',
  databaseURL: 'https://derma-june-webinar-default-rtdb.firebaseio.com',
  projectId: 'derma-june-webinar',
  storageBucket: 'derma-june-webinar.appspot.com',
  messagingSenderId: '389554103801',
  appId: '1:389554103801:web:6b6ab66ebb21922c760928',
  measurementId: 'G-1QKZRHXD69',
});

firebase.analytics();

export default app;
