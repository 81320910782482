import React from 'react';
import outside from '../../assets/animation/conf.png';
import Door from './Door';
import Billboard from './Billboard';

function Foreground({ billboardImage }) {
  return (
    <>
      <img src={outside} alt="foregound" className="animation__foreground" />
      <Billboard billboardImage={billboardImage} />
      <Door orientation="left" />
      <Door orientation="right" />
    </>
  );
}

export default Foreground;
