import React from 'react';
import 'firebase/database';
import { Button, Modal } from 'react-bootstrap';

function Poll({ handleClose, show, form }) {
  return (
    <Modal show={show} onHide={handleClose} keyboard={false} size="lg">
      <Modal.Body>
        {form && <iframe src={form.url} title="Feedback Form" className="modal-iframe" />}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default Poll;
