import React from 'react';
import { Row, Col } from 'react-bootstrap';
import 'firebase/firestore';
import RegistrationForm from '../Components/Forms/RegistrationForm';
// import TitleBanner from '../Components/TitleBanner';

const Register = () => {
  document.title = 'Derma Webinar - Register';
  return (
    <div className="container page-container">
      <Row className="home-row">

        <Col lg={12}>
          <RegistrationForm />
        </Col>

      </Row>
    </div>
  );
};

export default Register;
