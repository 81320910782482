import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import firebase from 'firebase/app';
import 'firebase/database';
import { approveQuestion, deleteQuestion } from '../Functions/RealtimeFunctions';

function IncomingQuestionsPage() {
  const eventId = 'eG9QFc7l6BLQefdxSmjK';
  const [questions, setQuestions] = useState([]);

  function handleApprove(x) {
    approveQuestion(eventId, x);
  }

  function handleReject(x) {
    deleteQuestion(eventId, x);
  }

  useEffect(() => {
    const storageRef = firebase.database().ref();
    storageRef.child(`events/${eventId}/questions`).on('value', (snapshot) => {
      const data = snapshot.val();
      if (data) {
        setQuestions(Object.keys(data)
          .filter((x) => !data[x].approved)
          .map((x) => ({ ...data[x], ...{ id: x } })));
      }
    });
  }, []);

  return (
    <div className="container page-container ">
      <h2>Questions to approve:</h2>
      {questions && questions.length > 0 ?
        (

          <table className="w-100 table table-striped">
            <thead className="thead-dark">
              <th>Question</th>
              <th>Name</th>
              <th>Actions</th>
            </thead>
            <tbody>
              {questions.map((x) => (
                <tr>
                  <td>
                    <i>&quot;{x.question}&quot;</i>
                  </td>
                  <td>
                    {x.name}
                  </td>
                  <td>
                    <Button className="btn btn-sm m-1" variant="success" onClick={() => { handleApprove(x.id); }}>Approve</Button>
                    <Button className="btn btn-sm m-1" variant="danger" onClick={() => { handleReject(x.id); }}>Reject</Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

        )
        : (

          <h5>No Questions to Approve</h5>

        )}
    </div>
  );
}

export default IncomingQuestionsPage;
