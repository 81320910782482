import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import 'firebase/auth';

import { AuthProvider } from './Auth/Auth';
import AdminRoute from './Auth/AdminRoute';
import LoginRoute from './Auth/LoginRoute';
import ReportingRoute from './Auth/ReportingRoute';

import Header from './Components/Header';
import LoginPage from './Pages/Login';
import RegisterPage from './Pages/Register';

import IncomingQuestions from './Reporting/IncomingQuestions';
import Questions from './Reporting/Questions';

import CookiePolicy from './Pages/CookiePolicy';
import LiveEvent from './Pages/LiveEvent';
import ForgottenPasswordPage from './Pages/ForgottenPassword';
import Admin from './Admin/Index';
import CreateEvent from './Admin/CreateEvent';
import EditEvent from './Admin/EditEvent';
// import EditSpeaker from './Admin/EditSpeaker';
import AdminEventDashboard from './Admin/AdminEventDashboard/AdminEventDashboard';
import AddCustomPoll from './Admin/AdminEventDashboard/CustomPolls/AddCustomPoll';
import EventReport from './Admin/EventReport';
import QuestionnaireIframe from './Components/QuestionnaireIframe';

import CookieConsent from './Components/CookieConsent';

import 'react-datetime/css/react-datetime.css';

const App = () => (
  <AuthProvider>
    <div className="page">
      <BrowserRouter
        getUserConfirmation={() => {
          /* Empty callback to block the default browser prompt */
        }}
      >
        <Header />
        <QuestionnaireIframe />
        <Switch>
          <Route exact path="/cookie-policy" component={CookiePolicy} />
          {/* Must be logged in */}

          {/* Must have admin role */}
          <AdminRoute exact path="/admin" component={Admin} />
          <AdminRoute exact path="/admin/create-event" component={CreateEvent} />
          <AdminRoute exact path="/admin/edit-event/:eventId" component={EditEvent} />
          <AdminRoute exact path="/admin/event-report/:eventId" component={EventReport} />
          <AdminRoute exact path="/admin/add-custom-poll/" component={AddCustomPoll} />
          <AdminRoute
            exact
            path="/admin/admin-event-dashboard/:eventId"
            component={AdminEventDashboard}
          />

          <ReportingRoute exact path="/questions-admin" component={IncomingQuestions} />
          <ReportingRoute exact path="/speaker-questions" component={Questions} />

          {/* Must be logged out to access */}
          <LoginRoute exact path="/register" component={RegisterPage} />
          <LoginRoute exact path="/login" component={LoginPage} />
          <LoginRoute exact path="/forgotten-password" component={ForgottenPasswordPage} />
          <Route exact path="/" component={LiveEvent} />
        </Switch>

      </BrowserRouter>
    </div>
    <CookieConsent />
  </AuthProvider>
);

export default App;
