import firebase from 'firebase/app';
import 'firebase/database';
import moment from 'moment';
import { getDocInfo } from './FirestoreFunctions';

export async function addQuestion(question, eventId, uid) {
  console.log(uid);
  getDocInfo('visitors', uid).then((res) => {
    console.log(res);
    firebase.database().ref().child(`events/${eventId}/questions`).push({
      name: `${res.firstName} ${res.lastName}`,
      question,
    });
  });
}

export async function dismissQuestion(eventId, questionId) {
  const storageRef = firebase.database().ref();
  return storageRef.child(`events/${eventId}/questions/${questionId}`).update({
    dismissed: true,
  });
}

export async function answerQuestion(eventId, questionId, answer, type = 'questions') {
  const storageRef = firebase.database().ref();
  return storageRef.child(`events/${eventId}/${type}/${questionId}`).update({
    answer,
  });
}

export async function removeAnswerToQuestion(eventId, questionId, type = 'questions') {
  const storageRef = firebase.database().ref();
  return storageRef.child(`events/${eventId}/${type}/${questionId}`).update({
    answer: null,
  });
}

export async function approveQuestion(eventId, questionId, type = 'questions') {
  const storageRef = firebase.database().ref();
  return storageRef.child(`events/${eventId}/${type}/${questionId}`).update({
    approved: true,
  });
}

export async function deleteQuestion(eventId, questionId, type = 'questions') {
  const storageRef = firebase.database().ref();
  return storageRef.child(`events/${eventId}/${type}/${questionId}`).remove();
}

export async function dismissNotification(eventId, questionId, type) {
  return deleteQuestion(eventId, questionId, type);
}

export function getUpdatedTotalTime(data, eventData, type) {
  // If total time is up to date don't change
  if (!data) {
    return 0;
  }

  if (data.upToDate) {
    return data.totalTimeOnline;
  }

  const sessionStart = data.last_online;
  const sessionEnd = data.last_offline;

  const currentOnlineTime = data.totalTimeOnline ? data.totalTimeOnline : 0;
  // If type is post event then ignore event start/end times
  if (type === 'post-event-record') {
    return currentOnlineTime + ((sessionEnd - sessionStart) / 1000);
  }

  const eventStart = moment(eventData.start).valueOf();
  const eventEnd = moment(eventData.end).valueOf();

  // If event is over keep total time the same
  if (sessionStart > eventEnd) {
    return data.totalTimeOnline;
  }

  // If event hasn't started set to 0s
  if (sessionEnd < eventStart) {
    return 0;
  }

  // If session started before event but carried on into the event window
  // treat event start as start time
  const start = sessionStart > eventStart ? sessionStart : eventStart;
  // If session end after event but started during the event window
  // treat event finish time as end time
  const end = sessionEnd < eventEnd ? sessionEnd : eventEnd;
  // Return the total time between the start and end in seconds
  return currentOnlineTime + ((end - start) / 1000);
}

export async function updateUserActivity(eventId, userActivity, post = false) {
  const type = post ? 'post-event-record' : 'user-record';

  // Get event info
  getDocInfo('events', eventId).then((result) => {
    // For each user record, update the total time if it not already up to date
    Object.keys(userActivity).forEach((key) => {
      if (!userActivity[key].upToDate) {
        const data = userActivity[key];
        const update = getUpdatedTotalTime(data, result, type);

        firebase.database().ref(`events/${eventId}/${type}/${key}`).update({
          totalTimeOnline: update,
          upToDate: true,
        });
      }
    });
  });
}

export async function isQuestionnaireForUser(userId, events) {
  return Object.keys(events)
    .map((key) => ({ ...events[key], ...{ id: key } }))
    .filter((event) => event['user-record'] !== undefined)
    .filter((event) => event['user-record'][userId] && event['user-record'][userId].showQuestionnaire === true)
    .map((event) => event.id);
}

export default addQuestion;
