import React, { useState, useEffect, useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { AuthContext } from './Auth';
import { getDocInfo } from '../Functions/FirestoreFunctions';
import Loader from '../Components/Loader';

const ReportingRoute = ({ component: RouteComponent, ...rest }) => {
  const { currentUser } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(true);
  const [userRole, setUserRole] = useState(undefined);

  useEffect(() => {
    if (currentUser !== undefined && currentUser?.uid !== undefined) {
      getDocInfo('users', currentUser.uid).then((result) => {
        setUserRole(result.role);
        setIsLoading(false);
      });
    }
  }, [currentUser]);

  return isLoading ? (
    <Loader />
  ) : (
    <Route
      {...rest}
      render={(routeProps) =>
        ((currentUser?.uid !== undefined && (userRole === 'admin' || userRole === 'reporting')) ? <RouteComponent {...routeProps} /> : <Redirect to="/" />)
      }
    />
  );
};

export default ReportingRoute;
