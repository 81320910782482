import React, { useState, useEffect } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import firebase from 'firebase/app';
import 'firebase/storage';
import Datetime from 'react-datetime';
import Select from 'react-select';
import JoditEditor from 'jodit-react';
import { createDoc, getSpeakers } from '../Functions/FirestoreFunctions';
import { validateEventForm, getEmptyEventFormData } from '../Functions/AdminFunctions';

const moment = require('moment');

const config = {
  readonly: false,
};

const CreateEvent = ({ history }) => {
  const [formData, setFormData] = useState(getEmptyEventFormData(moment().toISOString()));
  const [description, setDescription] = useState(undefined);
  const [agenda, setAgenda] = useState(undefined);
  const [errorBag, setErrorBag] = useState([]);
  const [outsideFoyerImage, setOutsideFoyerImage] = useState(undefined);
  const [foyerImage, setFoyerImage] = useState(undefined);
  const [iFrameBackground, setIFrameBackground] = useState(undefined);

  const [allSpeakers, setAllSpeakers] = useState({ value: '', label: '' });

  useEffect(() => {
    getSpeakers().then((response) => {
      setAllSpeakers(response.map((x) => ({ value: x.id, label: x.data().title })));
    });
  }, []);

  useEffect(() => {
    if (!formData) {
      setFormData(getEmptyEventFormData());
    }
  }, []);

  function updateFormData(update) {
    const newFormData = { ...formData };
    newFormData[update.field] = update.value;
    setFormData(newFormData);
  }

  useEffect(() => {
    updateFormData({ field: 'agenda', value: agenda });
  }, [agenda]);

  useEffect(() => {
    updateFormData({ field: 'description', value: description });
  }, [description]);

  function validateImages() {
    return [];
    // const errors = [];

    // if (!foyerImage) {
    //   errors.push('Foyer Image must be included');
    // }
    // if (!outsideFoyerImage) {
    //   errors.push('Outside Foyer Image must be included');
    // }
    // if (!iFrameBackground) {
    //   errors.push('iFrameBackground must be included');
    // }

    // return errors;
  }

  function createEvent() {
    if (formData !== undefined) {
      const errors = validateEventForm(formData);
      const imageErrors = validateImages();
      if (errors.length === 0 && imageErrors.length === 0) {
        try {
          createDoc('events', formData).then((docId) => {
            const storageRef = firebase.storage().ref();
            if (foyerImage) {
              const foyerRef = storageRef.child(`events/${docId}/${foyerImage.name}`);
              foyerRef.put(foyerImage);
            }

            if (iFrameBackground) {
              const iFrameBackgroundRef = storageRef.child(`events/${docId}/${iFrameBackground.name}`);
              iFrameBackgroundRef.put(iFrameBackground);
            }

            if (outsideFoyerImage) {
              const outsideFoyerRef = storageRef.child(`events/${docId}/${outsideFoyerImage.name}`);
              outsideFoyerRef.put(outsideFoyerImage);
            }

            history.push('/admin');
          });
        } catch (e) {
          console.log(e);
        }
      } else {
        setErrorBag([...errors, ...imageErrors]);
      }
    }
  }

  return (
    <div className="container page-container">
      <Container>
        <ul>
          {errorBag.map((error) => (
            <li className="text-danger">{error}</li>
          ))}
        </ul>

        <div className="form-group">
          <Row>
            <Col md={6}>
              <label htmlFor="titleInput">Event title</label>
              <input
                type="text"
                id="titleInput"
                className="form-control mb-2"
                placeholder="Event title"
                value={formData.title}
                onChange={(e) => {
                  updateFormData({ field: 'title', value: e.target.value });
                }}
              />
            </Col>
            <Col md={6}>
              <label htmlFor="locationInput">Location</label>
              <input
                type="text"
                id="locationInput"
                className="form-control mb-2"
                placeholder="Location"
                value={formData.location}
                onChange={(e) => {
                  updateFormData({ field: 'location', value: e.target.value });
                }}
              />
            </Col>
            <Col md={6}>
              <label htmlFor="facultyInput">Faculty</label>
              <input
                type="text"
                id="facultyInput"
                className="form-control mb-2"
                placeholder="Faculty"
                value={formData.faculty}
                onChange={(e) => {
                  updateFormData({ field: 'faculty', value: e.target.value });
                }}
              />
            </Col>
            <Col md={6}>
              <label htmlFor="iFrameURL">iFrameURL</label>
              <input
                type="text"
                id="iFrameURL"
                className="form-control mb-2"
                placeholder="iFrameURL"
                value={formData.iFrameURL}
                onChange={(e) => {
                  updateFormData({ field: 'iFrameURL', value: e.target.value });
                }}
              />
            </Col>
            <Col md={6}>
              <label htmlFor="feedbackForm">Feedback form URL</label>
              <input
                type="text"
                id="feedbackForm"
                className="form-control mb-2"
                placeholder="Feedback form"
                value={formData.feedbackForm}
                onChange={(e) => {
                  updateFormData({ field: 'feedbackForm', value: e.target.value });
                }}
              />
            </Col>
            <Col md={12}>
              <label htmlFor="description">Description</label>
              <JoditEditor
                id="description"
                value={description}
                config={config}
                onBlur={(e) => setDescription(e)}
              />
            </Col>
            <Col md={12}>
              <label htmlFor="agenda">Agenda</label>
              <JoditEditor
                id="agenda"
                value={agenda}
                config={config}
                onBlur={(e) => setAgenda(e)}
              />
            </Col>
            <Col md={6}>
              <label>Start date and time (GMT)</label>
              <Datetime
                onChange={(e) => {
                  updateFormData({ field: 'start', value: e.toISOString() });
                }}
                dateFormat="DD/MM/YYYY"
                value={moment(formData.start)}
              />
            </Col>
            <Col md={6}>
              <label>End date and time (GMT)</label>
              <Datetime
                onChange={(e) => {
                  updateFormData({ field: 'end', value: e.toISOString() });
                }}
                dateFormat="DD/MM/YYYY"
                value={moment(formData.end)}
              />
            </Col>
            <Col htmlFor="outsideFoyerImage" md={12} className="my-4">
              <label id="outsideFoyerImage">Outside Foyer Image</label>
              <input
                type="file"
                onChange={(e) => {
                  setOutsideFoyerImage(e.target.files[0]);
                  updateFormData({ field: 'outsideFoyerImage', value: e.target.files[0].name });
                }}
              />
            </Col>
            <Col htmlFor="foyerImage" md={12} className="my-4">
              <label id="foyerImage">Foyer Image </label>
              <input
                type="file"
                onChange={(e) => {
                  setFoyerImage(e.target.files[0]);
                  updateFormData({ field: 'foyerImage', value: e.target.files[0].name });
                }}
              />
            </Col>
            <Col htmlFor="iFrameBackground" md={12} className="my-4">
              <label id="iFrameBackground">iFrame Background </label>
              <input
                type="file"
                onChange={(e) => {
                  setIFrameBackground(e.target.files[0]);
                  updateFormData({ field: 'iFrameBackground', value: e.target.files[0].name });
                }}
              />
            </Col>
            <Col htmlFor="iFrameBackground" md={12} className="my-4">
              <Select options={allSpeakers} onChange={(e) => { updateFormData({ field: 'speakers', value: e }); }} isMulti />
            </Col>
          </Row>
        </div>
        <div className="form-buttons-container mt-3">
          <button id="btnSignUp" className="btn btn-warning text-white mr-3 float-right" onClick={createEvent} type="button">
            Create
          </button>
        </div>
      </Container>
    </div>
  );
};

export default CreateEvent;
