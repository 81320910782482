/* eslint-disable */
import firebase from 'firebase/app';
import app from '../Auth/FirebaseApp';
import 'firebase/firestore';
import 'firebase/storage';

// Get a doc from a named collection by ID
export async function getDocInfo(collection, docID) {
  const info = await app.firestore().collection(collection).doc(docID).get();
  return { ...info.data(), ...{ id: docID } };
}

// Get collection by name
export async function getCollection(collection) {
  return app
    .firestore()
    .collection(collection)
    .get()
    .then((querySnapshot) => querySnapshot);
}

export async function getSpeakers() {
  return getCollection('speakers').then((result) => result.docs);
}

export async function getFormattedSpeakers() {
  return getCollection('speakers').then((result) =>
    result.docs.map((x) => {
      const speaker = x.data();
      speaker.id = x.id;
      return speaker;
    }),
  );
}

// Create a new doc in a collection with specified fields
export async function createDoc(collection, fields) {
  return app
    .firestore()
    .collection(collection)
    .add(fields)
    .then((docRef) => docRef.id);
}

// Update selected fields a doc from a collection by id
export async function updateDoc(collection, docId, fields) {
  return app.firestore().collection(collection).doc(docId).update(fields);
}

// Delete a doc from a collection by id
export async function deleteDoc(collection, id) {
  try {
    app.firestore().collection(collection).doc(id).delete();
  } catch (error) {
    console.log(error);
  }
}

export async function getEventImage(eventId, imageName) {
  const storageRef = firebase.storage().ref();
  return storageRef.child(`events/${eventId}/${imageName}`).getDownloadURL();
}

// get the three event images for the animation
export async function getEventImages(eventId, event) {
  const images = {};

  images.foyerImage = event.foyerImage ? await getEventImage(eventId, event.foyerImage) : undefined;

  images.outsideFoyerImage = event.outsideFoyerImage
    ? await getEventImage(eventId, event.outsideFoyerImage)
    : undefined;

  images.iFrameBackground = event.iFrameBackground
    ? await getEventImage(eventId, event.iFrameBackground)
    : undefined;

  return images;
}

// get info of users from a list of uid
export async function getUsersInfo(userIds) {
  return userIds
    ? getCollection('users').then((users) =>
        users.docs.map((x) => x.data()).filter((x) => userIds.includes(x.uid)),
      )
    : [];
}

export async function handleCreateVisitor(firstName, lastName, email, country, specialty) {
  const dateObject = new Date();
  return createDoc('visitors', {
    email,
    firstName,
    lastName,
    country,
    specialty,
    createdAt: dateObject.toUTCString(),
  }).then((res) => res);
}

export async function handleCheckIn(visitorID, session) {
  const data = {};
  data[session] = true;
  const user = await getDocInfo('visitors', visitorID);

  if (user) {
    data[`${session}_time`] = user[`${session}_time`] ? user[`${session}_time`] + 1 : 1;
    return updateDoc('visitors', visitorID, data).then((res) => res);
  }

  data[`${session}_time`] = 0;
  return updateDoc('visitors', visitorID, data).then((res) => res);
}

export default getDocInfo;
